import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';

class HomePage extends Component {
  state = {
    authenticated: null
  };

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      const user = await this.props.authService.getUser();
      this.setState({ authenticated, user });
    }
  }



   render() {
     console.log(this.state)
     const { authenticated } = this.state;

     if (authenticated === null) return null;
     if (!authenticated) return (
       <div>
         <h1>Freelance Booster</h1>
         <h2>Click Login / Sign Up</h2>
       </div>
     )

      return (
        <div>
          <h1>Freelance Booster</h1>
          <h2>Next we need to install the slack app</h2>
          <a id="link" href="https://slack.com/oauth/v2/authorize?client_id=1039155172919.1168915671671&scope=chat:write&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
        </div>
      )
   }
}

export default withOktaAuth(HomePage);

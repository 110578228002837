import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect } from "react-router-dom";

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {redirect: false};
    this.exchangeToken = this.exchangeToken.bind(this);
  }

    componentDidMount() {
      this.exchangeToken();
    }


    async exchangeToken() {
      console.log("get")
      const self = this;
      console.log("token", await this.props.authService)
      console.log("token", await this.props.authService.getAccessToken())

      var url = window.location.href;
      var almostCode = url.split("code=")[1];
      var code = almostCode.split("&state=")[0]
      var state = decodeURIComponent(url.split("&state=")[1])
      console.log("code", code)
      // console.log("state", state)

      axios.post(`${process.env.API_URL}/v1/slack`, {
        code: code
      }, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
        },
      })
      .then(function (data, status) {
        console.log("Data: " + data + "\nStatus: " + status);
        console.log(data)
        self.setState({
          redirect: true
        });
      })
      .catch(function (err) {
       console.log(err)
      });
    }


   render() {
     console.log(this.state)
     if(this.state.redirect){
       return(
         <div>
          <Redirect to='/add-feed'/>;
         </div>
       )
     }else{
       return (
         <div>
             <h1>Authorizing Your App!</h1>
             <h2 id="response">Awaiting Response</h2>
             <Link to="/"> Home </Link>
         </div>
       )
     }
   }
}

export default withOktaAuth(HomePage);

// componentDidMount() {

//     // this.checkAuthentication();
//     // if authentication is true
//     this.getFeeds();
//   }

// const oauth = () => {
//   return (
//     <div>
//       <h1>Authorizing Your App!</h1>
//       <h2 id="response">Awaiting Response</h2>
//       <Link to="/"> Home </Link>
//     </div>
//   );
// };

// export default oauth;

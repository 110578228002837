import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from "prop-types";

class LoginButton extends Component {
  state = {
    authenticated: null
  };

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      const user = await this.props.authService.getUser();
      this.setState({ authenticated, user });
    }
  }

  login = () => this.props.authService.login();
  logout = () => {
    this.handleMenuClose();
    this.props.authService.logout();
  };

  handleMenuOpen = event => this.setState({ menuAnchorEl: event.currentTarget });
  handleMenuClose = () => this.setState({ menuAnchorEl: null });

  render() {
    const { authenticated } = this.state;

    if (authenticated === null) return null;
    if (!authenticated) return <button color="inherit" onClick={this.login}>Login / Sign Up</button>;

    return (
      <>
        <button color="inherit" onClick={this.logout}>Logout</button>
      </>
    );
  }
}

LoginButton.propTypes = {
  authState: PropTypes.object.isRequired,
  authService: PropTypes.object.isRequired
};

export default withOktaAuth(LoginButton);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from '../CardInput';

import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function HomePage() {
  const classes = useStyles();
  // State
  const { authState, authService } = useOktaAuth();
  const [ userInfo, setUserInfo ] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    console.log("user effect")
    if(!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      console.log("else")
      console.log(authState)

      authService.getUser().then( info => {
        console.log(info)
        setEmail(info.email)
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes

  const stripe = useStripe();
  const elements = useElements();

  // const han

  const handleSubmitSub = async (event) => {
    console.log("hit sub")
    // console.log(this)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }



    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    console.log(result)

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(authState)
      const res = await axios.post('http://localhost:9000/v1/users/sub', {'payment_method': result.paymentMethod.id, 'email': email}, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: `Bearer ${authState.accessToken}`,
        },
      });
      console.log(res.data)
      // eslint-disable-next-line camelcase
      const {client_secret, status} = res.data;

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function(result) {
          if (result.error) {
            console.log('There was an issue!');
            console.log(result.error);
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            console.log('You got the money!');
            alert('success!');
            // Show a success message to your customer
          }
        });
      } else {
        console.log('You ALSO got the money!');
        alert('success!');
        // No additional information was needed
        // Show a success message to your customer
      }
    }
  };

  return (
    <div>
    <h3>$5 per month. Cancel anytime. Email hello@freelancebooster.com</h3>
    <Card className={classes.root}>

      <CardContent className={classes.content}>
        <CardInput />
        <div className={classes.div}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitSub}>
            Subscription
          </Button>
        </div>
      </CardContent>
    </Card>
    </div>
  );
}

export default HomePage;

/* eslint-disable import/no-named-as-default */
import { NavLink, Route, Switch } from "react-router-dom";

import HomePage from "./containers/HomePage";
import NotFoundPage from "./NotFoundPage";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import { Security, LoginCallback } from '@okta/okta-react';

import LoginButton from './LoginButton';
import AddFeedPage from './containers/AddFeedPage';
import Oauth from './oauth';
import AccountPage from './containers/AccountPage'
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.PUBLISHABLE_KEY)

const oktaConfig = {
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  redirect_uri: `${window.location.origin}/implicit/callback`,
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  responseMode: "fragment",
};

console.log(process.env);
console.log(oktaConfig);

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    return (
      <div>
        <Security {...oktaConfig}>
        <Elements stripe={stripePromise}>
          <div>
            <NavLink exact to="/" >Home</NavLink>
            {' | '}
            <NavLink exact to="/add-feed" >Feeds</NavLink>
            {' | '}
            <NavLink exact to="/account" >Account</NavLink>
            {' | '}
            <LoginButton />
          </div>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/add-feed" component={AddFeedPage} />
            <Route path="/oauth" component={Oauth} />
            <Route path="/account" component={AccountPage} />
            <Route path="/implicit/callback" component={LoginCallback} />
            <Route component={NotFoundPage} />
          </Switch>
          </Elements >
        </Security>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);

import React from 'react';

class Feeds extends React.Component {

  render() {
    const feeds = this.props.feeds.map((feed, index) =>{
        if(this.props.editFeedId === feed.id){
          return(
            <div key={feed.id}>
              <input data-index={index} data-fieldname="feedName" value={feed.feedName} onChange={this.props.editFeedToBeUpdated} />
              <input data-index={index} data-fieldname="slackChannel" value={feed.slackChannel} onChange={this.props.editFeedToBeUpdated}/>
              <select data-index={index} data-fieldname="interval" value={feed.interval} onChange={this.props.editFeedToBeUpdated}>
                <option value="15" >15 Mins</option>
                <option value="30">30 Mins</option>
                <option value="60">60 Mins</option>
                <option value="360">6 Hours</option>
              </select>
              <input data-index={index} data-fieldname="feedUrl" value={feed.feedUrl} onChange={this.props.editFeedToBeUpdated}/>
              <button data-index={index} onClick={this.props.updateFeed}>Update</button>
            </div>
          )

        }else{
          return(
            <div key={feed.id}>
              <h4>{feed.feedName} / {feed.slackChannel} / {feed.interval}</h4>
              <h4>{feed.feedUrl}</h4>
              <button value={feed.id} onClick={this.props.editFeed}>Edit</button>
              <button name={feed.feedName} value={feed.id} onClick={this.props.deleteFeed}>Delete</button>
            </div>
          )
        }
      });
    return (
      <div>
        {feeds}
      </div>
    );
  }
}

export default Feeds;

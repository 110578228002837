import React, { Component } from 'react';
import axios from 'axios';
import Feeds from "../Feeds";
import { withOktaAuth } from '@okta/okta-react';

class HomePage extends Component {
    constructor(props) {
      super(props);
      this.state = {feedName: '', feedUrl: '', slackChannel: '', interval: 15, response: '', feeds: [], editFeedId: null, authenticated: null, user: null};
      this.handleFeedChange = this.handleFeedChange.bind(this);
      this.handleFeedNameChange = this.handleFeedNameChange.bind(this);
      this.handleChannelChange = this.handleChannelChange.bind(this);
      this.handleIntervalChange = this.handleIntervalChange.bind(this);
      this.addFeed = this.addFeed.bind(this);
      this.getFeeds = this.getFeeds.bind(this);
      this.editFeed = this.editFeed.bind(this);
      this.deleteFeed = this.deleteFeed.bind(this);
      this.editFeedToBeUpdated = this.editFeedToBeUpdated.bind(this);
      this.updateFeed = this.updateFeed.bind(this);
    }

    async checkAuthentication() {
      console.log("check auth")
      const authenticated = await this.props.authState.isAuthenticated;
      console.log("authed", authenticated)
      console.log("satte", this.state.authenticated)
      if (authenticated !== this.state.authenticated) {
        const user = await this.props.authService.getUser();
        console.log("user", user);
        this.setState({ authenticated, user });
      }
    }


    componentDidUpdate() {
      // console.log(this.state)
    }

    componentDidMount() {

      // this.checkAuthentication();
      // if authentication is true
      this.getFeeds();
    }

    handleFeedNameChange(event) {
      // Update State
      this.setState({
        feedName: event.target.value
      });
    }

    handleFeedChange(event) {
      // Update State
      this.setState({
        feedUrl: event.target.value
      });
    }

    handleChannelChange(event) {
      // Update State
      this.setState({
        slackChannel: event.target.value
      });
    }

    handleIntervalChange(event){
      // Update State
      this.setState({
        interval: parseInt(event.target.value)
      });
    }

    async addFeed() {
      const self = this;
      axios.post(`${process.env.API_URL}/v1/feeds/create-feed`, {
        feedName: this.state.feedName,
        feedUrl: this.state.feedUrl,
        slackChannel: this.state.slackChannel,
        interval: this.state.interval
      }, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
        },
      })
      .then(function (response) {
        self.getFeeds();
      })
      .catch(function (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log(err.request);
        } else {
          // anything else
          console.log(err)
        }
      });
    }

    async getFeeds() {
      console.log("get")
      const self = this;
      console.log(process.env)
      console.log(await this.props.authService.getAccessToken())
      axios.post(`${process.env.API_URL}/v1/feeds/get-feeds`, {}, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
        },
      })
      .then(function (response) {
        console.log("reponse", response)
        console.log("reponse", response.data.length)
        if(response.data.length === 0){
          response.data = []
        }
        self.setState({
          feeds: response.data
        });
      })
      .catch(function (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log(err.request);
        } else {
          // anything else
          console.log(err)
        }
      });
    }

    editFeed() {
      this.setState({
        editFeedId: Number(event.target.value)
      });
    }

    editFeedToBeUpdated() {
      this.state.feeds[event.target.dataset.index][event.target.dataset.fieldname] = event.target.value;
      this.setState({
        feeds: this.state.feeds
      });
    }

    async updateFeed() {
      const self = this;
      axios.post(`${process.env.API_URL}/v1/feeds/update-feed`, {
        feedName: this.state.feeds[event.target.dataset.index].feedName,
        feedUrl: this.state.feeds[event.target.dataset.index].feedUrl,
        slackChannel: this.state.feeds[event.target.dataset.index].slackChannel,
        interval: Number(this.state.feeds[event.target.dataset.index].interval),
        id: this.state.feeds[event.target.dataset.index].id
      }, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
        },
      })
      .then(function (response) {
        self.setState({
          editFeedId: null
        });
        self.getFeeds();
      })
      .catch(function (error) {
        console.log("err", error);
      });
    }

    async deleteFeed() {
      const self = this;
      const r = confirm(`Are you sure you want to delete ${event.target.name}? This cannot be undone.`);
      if (r == true) {
        const self = this;
        axios.post(`${process.env.API_URL}/v1/feeds/delete-feed`, {
          id: event.target.value
        }, {
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
          },
        })
        .then(function (response) {
          console.log("response delete", response)
          self.getFeeds();
        })
        .catch(function (error) {
          console.log("err", error);
        });
      } else {
        console.log("reject");
      }
    }


   render() {
     console.log(this.state)
      return (
        <div>
          <h1>Add Feeds</h1>
          <h2>Feed Name</h2>
          <input onChange={this.handleFeedNameChange} placeholder="rss feed name"/>
          <h2>Url</h2>
          <input onChange={this.handleFeedChange} placeholder="rss feed url"/>
          <h2>Slack Channel</h2>
          <input onChange={this.handleChannelChange} placeholder="slack channel"/>
          <h2>Interval</h2>
          <select value={this.state.interval} onChange={this.handleIntervalChange}>
            <option value="15" >15 Mins</option>
            <option value="30">30 Mins</option>
            <option value="60">60 Mins</option>
            <option value="360">6 Hours</option>
          </select>
          <br />
          <button onClick={this.addFeed}>Submit</button>
          <h4>{this.state.response}</h4>
          <h1>Live Feeds</h1>
          <h3>Feed Name / Channel / Interval</h3>
          <h3>Feed Url</h3>
          <Feeds
            feeds={this.state.feeds}
            editFeedId={this.state.editFeedId}
            editFeed={this.editFeed}
            deleteFeed={this.deleteFeed}
            editFeedToBeUpdated={this.editFeedToBeUpdated}
            updateFeed={this.updateFeed}/>
        </div>
      )
   }
}

export default withOktaAuth(HomePage);
